import React from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation
import "./Footer.css";
import { assets } from "../../assets/images/assest";
const Footer = () => {
  return (
    <>
      <footer className="text-center text-lg-start bg-body-tertiary text-muted">
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <span>Get connected with us on social networks:</span>
          </div>
          <div>
            <a href="https://www.facebook.com" className="me-4 text-reset">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.twitter.com" className="me-4 text-reset">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://www.google.com" className="me-4 text-reset">
              <i className="fab fa-google"></i>
            </a>
            <a href="https://www.instagram.com" className="me-4 text-reset">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com" className="me-4 text-reset">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://www.github.com" className="me-4 text-reset">
              <i className="fab fa-github"></i>
            </a>
          </div>
        </section>

        <section className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <img
                    src={assets[0].img}
                    className="logo_img img-fluid"
                    alt="Logo"
                    style={{ height: "50px", width: "100%" }}
                  />
                </h6>
                <p style={{ textAlign: "justify" }}>
                  Welcome to IT Helpline, where we empower businesses with
                  innovative digital solutions. Our dedicated team of experts
                  specializes in web development, graphic design, social media
                  management, and data analytics, ensuring tailored services
                  that meet your unique needs.
                </p>
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Quick Links</h6>
                <p>
                  <Link to="/Home" className="text-reset  text-decoration-none">
                    Home
                  </Link>
                </p>
                <p>
                  <Link to="/About" className="text-reset text-decoration-none">
                    About
                  </Link>
                </p>
                <p>
                  <Link
                    to="/Services"
                    className="text-reset text-decoration-none"
                  >
                    Services
                  </Link>
                </p>
                <p>
                  <Link
                    to="/Contact"
                    className="text-reset text-decoration-none"
                  >
                    Contact
                  </Link>
                </p>
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Products</h6>
                <p>
                  <Link
                    to="/course"
                    className="text-reset text-decoration-none"
                  >
                    Microsoft Share Point
                  </Link>
                </p>
                <p>
                  <Link
                    to="/course"
                    className="text-reset text-decoration-none"
                  >
                    Microsoft Projects
                  </Link>
                </p>
                <p>
                  <Link
                    to="/course"
                    className="text-reset text-decoration-none"
                  >
                    Power BI
                  </Link>
                </p>
                <p>
                  <Link
                    to="/course"
                    className="text-reset text-decoration-none"
                  >
                    Microsoft Power Automate
                  </Link>
                </p>
              </div>
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Services</h6>
                <p>
                  <Link
                    to="/course"
                    className="text-reset text-decoration-none"
                  >
                    Web Development
                  </Link>
                </p>
                <p>
                  <Link
                    to="/course"
                    className="text-reset text-decoration-none"
                  >
                    Graphics Design
                  </Link>
                </p>
                <p>
                  <Link
                    to="/course"
                    className="text-reset text-decoration-none"
                  >
                    Brand Book
                  </Link>
                </p>
                <p>
                  <Link
                    to="/course"
                    className="text-reset text-decoration-none"
                  >
                    Socical Media Mangement
                  </Link>
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <i className="fas fa-home me-3"></i> Darbhanga Bihar
                </p>
                <p>
                  <i className="fas fa-envelope me-3"></i>
                  info@example.com
                </p>
                <p>
                  <i className="fas fa-phone me-3"></i> + 01 234 567 88
                </p>
                <p>
                  <i className="fas fa-phone me-3"></i> + 01 234 567 89
                </p>
              </div>
            </div>
          </div>
        </section>

        <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          Design and Developed By:
          <a className="text-reset fw-bold" href="https://Digifly.live/">
            Digifly.live
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
