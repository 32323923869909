import React from "react";
import { Link, NavLink } from "react-router-dom";
import { assets } from "../../assets/images/assest";
import "./Navbar.css";

const MyNavbar = () => {
  // Navigation items array
  const navItems = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Services", path: "/services" },
    { name: "Product", path: "/products" },
    { name: "Contact", path: "/contact" },
  ];

  return (
    <div className="div  navbar_container">
      <nav className="my_navbar navbar navbar-expand-lg bg-light fixed-top">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img
              src={assets[0].img}
              className="logo_img"
              alt="Logo"
              style={{ height: "50px", width: "auto" }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>


          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              {navItems.map((item) => (
                <li key={item.name} className="nav-item">
                  <NavLink
                    className="nav-link fs-4 nav_item"
                    to={item.path}
                    exact={item.path === "/"} // Only 'Home' should be exact
                  >
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default MyNavbar;
