import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import contactBackground from "../../assets/images/Contact.png";

const Contact = ({ course }) => {
  const form = useRef();

  const message = course
    ? `Hi, I am interested in the ${course.name}`
    : "Hi, I am interested in the course";

  const [courseName, setCourseName] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [location, setLocation] = useState("");
  const [solution, setSolution] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    const emailParams = {
      from_name: name,
      from_email: email,

      from_contact: phone,
      from_course: courseName,
      from_website: website,
      from_location: location,
      from_solution: solution,
      message: message,
    };

    emailjs
      .send(
        "service_6izuslm", // Replace with your EmailJS service ID
        "template_u330clc", // Replace with your EmailJS template ID
        emailParams,
        "x-OYu6erY59KEk4E7" // Replace with your EmailJS public key
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response);
          alert("Your query has been sent!");
        },
        (error) => {
          console.error("FAILED...", error);
          alert("Failed to send your query. Please try again later.");
        }
      );

  };

  return (
    <>

      <div
        className="container contact my_container"

      >

        <div className="row">
          <div className="col-12">
            <h1 className="text-center">
              <span style={{ color: "black" }}>Contact </span><span style={{ color: "#356d8a" }}>Us</span>
            </h1>

          </div>
        </div>


        <div className="row ">
          <div className=" d-flex justify-content-center align-items-center"
            style={{
              backgroundImage: `url(${contactBackground})`,
              maxWidth: "100%",
              height: "auto",
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "10px",
              padding: "30px"
              // position: "relative"
            }}
          >

            <div className="col-sm-12 col-md-8 col-lg-6">
              {/* backgroundImage div */}



              <div
                className="card shadow-lg border-0 rounded-lg"
                style={{ borderRadius: "20px" }}
              >
                <div className="card-body text-center">
                  <h2 className="text-center mt-4 mb-4 fs-2">Enquiry Form</h2>
                  <form ref={form} onSubmit={sendEmail}>
                    <div className="row p-2">
                      <div className="col">
                        <input
                          type="text"
                          name="from_name"
                          className="form-control"
                          placeholder="Enter your name"
                          aria-label="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>

                      <div className="col">
                        <input
                          type="text"
                          name="from_contact"
                          className="form-control"
                          placeholder="Enter your phone number"
                          aria-label="Phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>

                    </div>


                    <div className="row p-2">

                      <div className="col">
                        <input
                          type="email"
                          name="from_email"
                          className="form-control"
                          placeholder="Enter your email"
                          aria-label="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          name="from_company"
                          className="form-control"
                          placeholder="Enter your company name"
                          aria-label="Company"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                        />
                      </div>

                    </div>

                    <div className="row p-2">

                      <div className="col">
                        <input
                          type="text"
                          name="from_website"
                          className="form-control"
                          placeholder="Enter your website URL"
                          aria-label="Website"
                          value={website}
                          onChange={(e) => setWebsite(e.target.value)}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          name="from_location"
                          className="form-control"
                          placeholder="Enter your location/city"
                          aria-label="Location"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        />
                      </div>

                    </div>

                    <div className="row p-2">
                      <div className="col">
                        <textarea
                          type="text"
                          name="from_solution"
                          className="form-control"
                          placeholder="Describe your solution needs"
                          aria-label="Solution"
                          value={solution}
                          onChange={(e) => setSolution(e.target.value)}
                        />
                      </div>

                    </div>

                    <button
                      type="submit"
                      className="btn btn-success mt-4 ps-2 mx-4"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
