import React from "react";
import "./About.css";
import { assets } from "../../assets/images/assest";

const About = () => {
  const aboutImages = assets.filter((asset) => asset.name === "about");

  return (
    <div className="container about-section my_container">
      <div className="row">
        <div className="col-12">
          <h1 className="text-center">
            About <span style={{ color: "#356d8a" }}>Us</span>
          </h1>
        </div>
      </div>
      <div className="row align-items-center">
        {/* Image Section */}
        <div className="col-lg-6 text-center">
          <div className="about-image-container">
            {aboutImages.map((image, index) => (
              <img
                key={index}
                src={image.img}
                alt={`About Image ${index + 1}`}
                className={`img-fluid rounded about-image ${
                  index === 0 ? "primary" : "second"
                }`}
              />
            ))}
          </div>
        </div>

        {/* Text Section */}
        <div className="col-lg-6">
          <h2 className="about_title" style={{ color: "#4886aa" }}>
            Discover Your Perfect Business Development
          </h2>
          <p className="about-description" style={{ textAlign: "justify" }}>
            Welcome to IT Helpline, where we empower businesses with innovative
            digital solutions. Our dedicated team of experts specializes in web
            development, graphic design, social media management, and data
            analytics, ensuring tailored services that meet your unique needs.
            Founded on creativity, collaboration, and excellence, our mission is
            to enhance your brand's presence and help you achieve your business
            goals. From crafting responsive websites to designing impactful
            graphics and implementing tools like Microsoft Power BI and
            SharePoint, we bring your vision to life. At IT Helpline, we value
            strong client relationships and understand your challenges. Our
            personalized approach focuses on delivering results that drive your
            success. Partner with us to unlock your brand’s full potential in
            the digital landscape.
          </p>
          <div className="buttons mt-4">
            <button
              className="btn btn-success me-3"
              style={{ backgroundColor: "#4886aa" }}
            >
              Contact Us
            </button>
            <button
              className="btn btn-secondary"
              style={{ backgroundColor: "#AEDEDF", color: "black" }}
            >
              Project PDF
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


export default About;
