import React from "react";
import HeaderImage from "../../assets/images/Header.jpg";
import "./Header.css";
import gsap from "gsap";
import { motion } from "framer-motion";

import { useGSAP } from "@gsap/react";
const Header = () => {
  useGSAP(() => { });

  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      transition={{ duration: 2 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className="container-fluid mt-4  ">
      <div className="row">
        <div className="col-lg-6  col-12 mt-4 p-4">
          <div className="card border-0">
            <h1 className=" htext ps-4 pt-4">
              Empowering Your <span style={{ color: "#4886AA" }}>Business</span>{" "}
              with Cutting-Edge{" "}
              <span style={{ color: "#4886AA" }}>IT Solutions</span>
            </h1>
            <p className="ps-4 mt-0">
              Expert support for Google, Microsoft Power BI, Power Apps, and
              more, including professional app and website design and
              development, as well as comprehensive social media management.
            </p>
            <div>
              <div
                className="btn ms-4 rounded-5"
                style={{ backgroundColor: "#4886aa" }}
              >
                Contact Us
              </div>
              <div
                className="btn ms-2 rounded-5"
                style={{ backgroundColor: "#AEDEDF" }}
              >
                Project
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-12 ">
          <div
            className="card custom-border"
            style={{
              borderLeft: "5px solid #4886AA",
              borderBottom: "5px solid #4886AA",
              paddingLeft: "10px",
              paddingBottom: "10px",
            }}
          >
            <img
              src={HeaderImage}
              alt="Header"
              style={{ borderRadius: "200% 0% 15% 85% / 0% 100% 0% 100%" }}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Header;
