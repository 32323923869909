import React, { useEffect, useState } from "react";
import { Products, Services as ServicesData } from "../../assets/images/assest";
import "./Services.css";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const Services = () => {
  const [activeTab, setActiveTab] = useState("products");


  const location = useLocation()
  let path = location.pathname;

  console.log("path")
  console.log(path)

  useEffect(() => {
    if (location.pathname == '/products') {
      setActiveTab("products")
    } else if (location.pathname == '/services') {
      setActiveTab("services")
    }
  }, [location])



  const dataToShow = activeTab === "products" ? Products : ServicesData;

  return (
    <div className="div my_container">
      <motion.div
        initial={{ opacity: 0, x: 200 }}
        transition={{ duration: 2 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="services-section py-4"
      >
        <div className="container">
          <h1 className="text-center mb-4">
            Product &{" "}
            <span className="highlight" style={{ color: "#356d8a" }}>
              Services
            </span>
          </h1>
          <div className="button-group d-flex justify-content-center gap-3 mt-4">
            <button
              className={`btn ${activeTab === "product" ? "btn-primary" : "btn-outline-primary"
                } px-4 py-2`}
              onClick={() => setActiveTab("product")}
            >
              Products
            </button>
            <button
              className={`btn ${activeTab === "services"
                ? "btn-secondary"
                : "btn-outline-secondary"
                } px-4 py-2`}
              onClick={() => setActiveTab("services")}
            >
              Services
            </button>
          </div>

          <div className="row mt-5">
            {dataToShow.map((item, index) => (
              <div
                className={`${activeTab === "products" ? "col-lg-6" : "col-lg-3"
                  } col-12 mb-4`}
                key={index}
              >
                <div className="card service_custom_card">
                  <div className="image-container">
                    <img
                      src={item.img}
                      alt={`Image of ${item.name}`}
                      className="card-img-top circular-image"
                    />
                  </div>
                  <div className="card-body text-center">
                    <h2 className="card-title fs-4">{item.name}</h2>
                    <p className="card-text">{item.description}</p>
                    <div className="additional-images container">
                      {item.img2 && (
                        <img
                          src={item.img2}
                          alt={`Additional image 1 of ${item.name}`}
                          className="circular-image"
                        />
                      )}
                      {item.img3 && (
                        <img
                          src={item.img3}
                          alt={`Additional image 2 of ${item.name}`}
                          className="circular-image"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Services;
