import React, { useState, useEffect } from "react";
import "./Whatscall.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";


const Whatscall = () => {
  // Default WhatsApp number
  const whatsappNumber = "+1234567890";
  const [message, setMessage] = useState("Hello! I'd like to chat.");
  const [scrolled, setScrolled] = useState(false);

  // Add scroll effect
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 100); // Show buttons when scrolled down 100px
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="fixed-buttons">

      {/* WhatsApp Button */}
      <a
        href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
          message
        )}`}
        target="_blank"
        rel="noopener noreferrer"
        className={`btn btn-success btn-lg circle-button ${scrolled ? "scrolled" : ""
          }`}
      >
        <FontAwesomeIcon icon={faWhatsapp} beat className="whatapp_icon" />

        {/* <i className="fab fa-whatsapp"></i> */}
      </a>

      {/* Phone Call Button */}
      <a
        href={`tel:${whatsappNumber}`}
        className={`btn btn-primary btn-lg circle-button text-light ${scrolled ? "scrolled" : ""
          }`}
      >
        <FontAwesomeIcon icon={faPhone} flip className="phone_icon" />
        {/* <i className="fas fa-phone-alt"></i> */}
      </a>
    </div>
  );
};

export default Whatscall;
