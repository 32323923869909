import React from "react";
import { testimonials } from "../../assets/images/assest"; // Import your testimonials

const Testimonial = () => {
  return (
    <>
      <h1 className="text-center mb-5 mt-5">
        Our Satisfied <span className="text-primary">Clients</span>
      </h1>

      <div
        className="container p-4"
        style={{ backgroundColor: "#356d8a", borderRadius: "45px" }}
      >
        <div
          id="testimonialCarousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className="card shadow-lg p-4"
                    style={{ width: "30rem", borderRadius:"20px" }}
                  >
                    <div className="card-body">
                      <p className="card-text text-center">
                        {testimonial.text}
                      </p>
                    </div>
                    <div className="card-footer text-center">
                      <img
                        src={testimonial.img}
                        alt={`Testimonial ${index + 1}`}
                        className="rounded-circle border border-4 border-primary"
                        style={{
                          width: "120px",
                          height: "120px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Carousel Controls */}
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#testimonialCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#testimonialCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
