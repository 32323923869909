// import logo from "./Logo.png";
import logo from "./ithelpline_logo.png";

// ithelpline_logo
import AboutImage from "./About-2.jpg";
import AboutImage_1 from "./About-2.jpg";
// import Header from "./Header.jpg";
import contactBackground from "./Contact.png";
import Product_1 from "./Product-1.png";
import Product_2 from "./Product-2.png";
import Product_3 from "./Product-3.png";
import Product_4 from "./Product-4.png";
import Services_1 from "./Services-1.png";
import Services_2 from "./Services-2.jpg";
import Services_3 from "./Services-3.png";
import Services_5 from "./Services-5.png";
import Services_4 from "./Services-4.png";
import Services_6 from "./Services-6.jpg";

import Team_1 from "./Team-1.jpg";
import Team_2 from "./Team-2.jpg";
import Team_3 from "./Team-3.jpg";
import Team_4 from "./Team-4.jpg";
export const assets = [
  { name: "logo", img: logo },
  { name: "about", img: AboutImage },
  { name: "about", img: AboutImage_1 },
];

export const Contact = [{ img: contactBackground }];
// export const Header = [{ img: Header }];
export const Products = [
  {
    img: Product_1,
    img2: Product_3,
    img3: Product_4,
    name: "Microsoft Power Automate",
    description:
      "Power Automate streamlines workflows by automating repetitive tasks, boosting efficiency and productivity across your business operations.",
  },
  {
    img: Product_2,
    img2: Services_5,
    img3: Services_6,
    name: "Google Products",
    description:
      "Power BI transforms data into insights with interactive dashboards and reports, enabling data-driven decisions and enhanced business visibility.",
  },
  // {
  //   img: Product_3,
  //   name: "Microsoft Projects",
  //   description:
  //     "Microsoft Project simplifies project planning, resource management, and task tracking, helping teams deliver projects on time and within budget.",
  // },
  // {
  //   img: Product_4,
  //   name: "Microsoft Share Point",
  //   description:
  //     "Microsoft SharePoint enables seamless collaboration, secure file sharing, and streamlined workflows to keep your team connected and productive.",
  // },
];

export const Services = [
  {
    img: Services_1,
    name: "Sociacal Media management",
    description:
      "Managing your social media presence to boost engagement, grow your audience, and strengthen your brand with tailored, consistent content.",
  },
  {
    img: Services_2,
    name: "Graphics Design",
    description:
      "Delivering eye-catching graphic design solutions that enhance brand identity, engage audiences, and communicate your message effectively.",
  },
  {
    img: Services_3,
    name: "Web Development",
    description:
      "Providing end-to-end web development services, we create responsive, user-friendly websites tailored to enhance your brand and engage your audience.",
  },
  {
    img: Services_4,
    name: "Brand Book",
    description:
      "Crafting a comprehensive Brand Book that defines your brand’s identity, guidelines, and values, ensuring consistency across all communications.",
  },
];

export const testimonials = [
  {
    text: "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean commodo ligula eget dolor.”",
    img: Team_1,
  },
  {
    text: "“Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.”",
    img: Team_2,
  },
  {
    text: "“At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum.”",
    img: Team_3,
  },
  {
    text: "“Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur.”",
    img: Team_4,
  },
];
