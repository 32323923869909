import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Home from "./Components/Home";
import Header from "./Components/Header/Header";
import Services from "./Components/Services/Services";
import Footer from "./Components/Footer/Footer";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Testimonial from "./Components/Testimonial/Testimonial";
import Whatscall from "./Components/WhatsappCall/Whatscall";
import MyNavbar from "./Components/Navbar/MyNavbar";
const App = () => {
  return (
    <div>
      <Router>
        <MyNavbar />
        <Routes>
          <Route path="/" element={<Home />} />x
          <Route path="/header" element={<Header />} />
          <Route path="/services" element={<Services />} />
          <Route path="/products" element={<Services />} />


          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/whatscall" element={<Whatscall />} />
        </Routes>
        <Footer />

      </Router>
    </div>
  );
};

export default App;
