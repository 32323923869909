import React from "react";
import Header from "./Header/Header";
import Services from "./Services/Services";
import Footer from "./Footer/Footer";
import Testimonial from "./Testimonial/Testimonial";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Whatscall from "./WhatsappCall/Whatscall";

const Home = () => {
  return (
    <div>
      <Header />
      <Services />
      <About />
      <Contact />
      <Testimonial />
      {/* <Footer /> */}
      <Whatscall />
    </div>
  );
};

export default Home;
